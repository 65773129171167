import { Box, Icon, IconButton, Image, Text } from '@chakra-ui/react';
import { BsFillImageFill } from 'react-icons/bs';
import { FiTrash2 } from 'react-icons/fi';

import { styles } from './styles';

const AddFileWindow = ({
  selectedFiles,
  onDeleteFile,
  wrapperProps,
  initialImage,
  setInitialImage,
}) => {
  const handleDeleteFile = () => {
    if (selectedFiles.length > 0) {
      onDeleteFile(selectedFiles[0]);
    }
    if (initialImage) {
      setInitialImage(undefined);
    }
  };
  return (
    <Box {...wrapperProps} background='blue.50' sx={styles.fileWindowWrapper}>
      {selectedFiles?.length === 0 && !initialImage ? (
        <Box sx={styles.imageAddingDescriptionWrapper}>
          <Icon as={BsFillImageFill} sx={styles.iconWrapper} />
          <Text sx={styles.addInstructionTitle}>Перетащите изображение или нажмите сюда</Text>
          <Text sx={styles.addInstructionSubtitle}>
            Поддерживаемые форматы: .jpg, .png, .gif Размер файла - до 10 Мб
          </Text>
        </Box>
      ) : (
        <Box sx={styles.imageWrapper}>
          <IconButton
            onClick={handleDeleteFile}
            sx={styles.deleteBtn}
            icon={<FiTrash2 size='50%' />}
          />
          <Image src={selectedFiles[0]?.preview || initialImage} sx={styles.imageView} />
        </Box>
      )}
    </Box>
  );
};

export default AddFileWindow;
