import { Box, Button, Center, Spinner, Text } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { FiPlusCircle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import AddFileWindow from '../../../components/add-file-window';
import TextArea from '../../../components/text-area';
import TextEditor from '../../../components/text-editor';
import TextField from '../../../components/text-field';
import { MAX_FILE_SIZE_BYTES, MAX_FILE_SIZE_MB } from '../../../constants';
import { useAlert, useFileUpload } from '../../../hooks';
import { useAddBlogArticleMutation } from '../../../hooks/useMutations';
import { blogSchema } from '../../../validation/schemas';
import { styles } from './styles';

const AddBlog = () => {
  const { selectedFiles, onDeleteFile, getRootProps, getInputProps, onResetFiles } =
    useFileUpload();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imageDownloadInProcess, setImageDownloadInProcess] = useState(false);
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const [addBlogArticleMutation] = useAddBlogArticleMutation();
  return (
    <Box sx={styles.reviewsListWrapper}>
      {isSubmitting && (
        <Box sx={styles.overlay}>
          <Text sx={styles.overlayText} color='blue.200'>
            Добавляем новый блог...
          </Text>
          <Spinner
            thickness='0.3vw'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.200'
            sx={styles.loadingSpinner}
          />
        </Box>
      )}
      <Formik
        initialValues={{
          title: '',
          descriptionText: '',
          articleContent: '',
        }}
        validationSchema={blogSchema}
        onSubmit={(values) => {
          setIsSubmitting(true);
          if (selectedFiles.length === 0) {
            showAlert({
              message: 'Добавьте фото!',
              variant: 'warning',
            });
          } else if (selectedFiles[0].size > MAX_FILE_SIZE_BYTES) {
            showAlert({
              message: `Размер файла превышает допустимый лимит в ${MAX_FILE_SIZE_MB} Мб`,
              variant: 'error',
            });
            onResetFiles();
            setIsSubmitting(false);
          } else if (selectedFiles.length > 0) {
            addBlogArticleMutation({
              variables: {
                input: {
                  title: values.title,
                  description: values.descriptionText,
                  content: values.articleContent,
                },
                file: selectedFiles[0],
              },
              onError: () => {
                showAlert({ message: 'Ошибка создании блога!', variant: 'error' });
                setIsSubmitting(false);
              },
              onCompleted: () => {
                showAlert({
                  message: 'Блог добавлен.',
                  variant: 'success',
                });
                navigate('/admin/blogs');
                setIsSubmitting(false);
              },
            });
          } else {
            setIsSubmitting(false);
          }
        }}
      >
        <Form>
          <Box sx={styles.headingBar}>
            <Text sx={styles.pageTitle}>Добавление блога</Text>
            <Button
              leftIcon={<FiPlusCircle />}
              colorScheme='green'
              type='submit'
              disabled={imageDownloadInProcess}
            >
              Добавить блог
            </Button>
          </Box>
          <Center flexDirection='column'>
            <Field name='title'>
              {({ field, meta }) => (
                <TextField
                  inputType='text'
                  placeholder='Название'
                  touched={meta.touched}
                  error={meta.error}
                  {...field}
                  required
                  wrapperProps={{
                    width: '47.5vw',
                    mb: '2vw',
                  }}
                />
              )}
            </Field>
            <div {...(selectedFiles.length > 0 ? null : { ...getRootProps() })}>
              <input {...getInputProps()} name='file' multiple={false} />
              <AddFileWindow
                disabled={selectedFiles.length > 0}
                selectedFiles={selectedFiles}
                onDeleteFile={onDeleteFile}
                wrapperProps={{
                  width: '47.5vw',
                  height: '10vw',
                }}
              />
            </div>
            <Field name='descriptionText'>
              {({ field, meta }) => (
                <TextArea
                  placeholder='Описание блога'
                  touched={meta.touched}
                  error={meta.error}
                  {...field}
                  required
                  wrapperProps={{
                    width: '47.5vw',
                    mt: '2vw',
                  }}
                />
              )}
            </Field>
          </Center>
          <Center mt='2vw' mb='2vw'>
            <Field name='articleContent'>
              {({ form, field }) => {
                const { setFieldValue } = form;
                return (
                  <TextEditor
                    {...field}
                    setFieldValue={setFieldValue}
                    setImageDownloadInProcess={setImageDownloadInProcess}
                  />
                );
              }}
            </Field>
          </Center>
        </Form>
      </Formik>
    </Box>
  );
};

export default AddBlog;
