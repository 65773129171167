export const styles = {
  tableWrapper: {
    marginTop: '1vw',
    width: '100%',
    marginBottom: '2vw',
  },
  tableRow: {},
  cellWithMenu: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: '0vw',
    justifyContent: 'flex-end',
  },
  menuBtn: {
    marginLeft: '1vw',
  },
  menuDeleteText: {
    color: 'crimson',
  },
  modalTextWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
};
