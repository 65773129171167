export const styles = {
  reviewsListWrapper: {
    marginTop: '1.7vw',
    paddingX: '1vw',
  },
  headingBar: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1vw',
  },
  pageTitle: {
    fontWeight: 600,
    fontSize: '1vw',
  },
  addReviewBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#27485C',
    borderRadius: '0.625vw',
    padding: '0.4vw',
    color: '#fff',
    fontSize: '0.8vw',
    cursor: 'pointer',
    _hover: {
      opacity: 0.9,
      transition: '0.3s',
    },
  },
  loadingSpinner: {
    height: '5vw',
    width: '5vw',
    marginTop: '5vw',
  },
};
