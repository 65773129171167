import { Box, Button, Center, Spinner, Text } from '@chakra-ui/react';
import React from 'react';
import { FiPlusCircle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import SearchBar from '../../../components/search-bar';
import { useGetBlogArticlesQuery } from '../../../hooks/useQueries';
import BlogsTable from './components/blogs-table';
import { styles } from './styles';

const BlogsList = () => {
  const [filter, setFilter] = React.useState({
    searchString: '',
  });

  const { data: blogs, loading } = useGetBlogArticlesQuery({
    variables: {
      name: filter.field,
    },
  });
  const navigate = useNavigate();
  return (
    <Box sx={styles.reviewsListWrapper}>
      <Box sx={styles.headingBar}>
        <Text sx={styles.pageTitle}>Блоги</Text>
        <Button leftIcon={<FiPlusCircle />} onClick={() => navigate('add')}>
          Добавить блог
        </Button>
      </Box>
      <SearchBar placeholder={'Поиск'} onChangeField={setFilter} />

      {loading ? (
        <Center>
          <Spinner
            thickness='0.3vw'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.200'
            sx={styles.loadingSpinner}
          />
        </Center>
      ) : (
        <BlogsTable data={blogs?.getBlogArticles} />
      )}
    </Box>
  );
};

export default BlogsList;
