import { Box, Button, Center, Spinner, Text } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { FiSave } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom';

import AddFileWindow from '../../../../components/add-file-window';
import TextArea from '../../../../components/text-area';
import TextEditor from '../../../../components/text-editor';
import TextField from '../../../../components/text-field';
import { useAlert, useFileUpload, useGetBlogArticleQuery } from '../../../../hooks';
import { useUpdateBlogArticleMutation } from '../../../../hooks/useMutations';
import { blogSchema } from '../../../../validation/schemas';
import { styles } from '../styles';

const EditBlog = () => {
  const { selectedFiles, onDeleteFile, getRootProps, getInputProps } = useFileUpload();
  const [updateBlogArticleMutation] = useUpdateBlogArticleMutation();
  const [imageDownloadInProcess, setImageDownloadInProcess] = useState(false);
  const [initialImage, setInitialImage] = React.useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: article } = useGetBlogArticleQuery({
    variables: {
      id,
    },
    onCompleted: (response) => {
      setInitialImage(response.getBlogArticle.photoUrl);
    },
  });

  const initialValues = {
    title: article?.getBlogArticle.title || '',
    descriptionText: article?.getBlogArticle.description || '',
    articleContent: article?.getBlogArticle.content || '',
  };

  return (
    <Box sx={styles.reviewsListWrapper}>
      {isSubmitting && (
        <Box sx={styles.overlay}>
          <Text sx={styles.overlayText} color='blue.200'>
            Сохраняем изменения...
          </Text>
          <Spinner
            thickness='0.3vw'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.200'
            sx={styles.loadingSpinner}
          />
        </Box>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={blogSchema}
        enableReinitialize
        onSubmit={(values) => {
          setIsSubmitting(true);
          const blogInput = {
            id,
            title: values.title,
            description: values.descriptionText,
            content: values.articleContent,
          };
          if (selectedFiles.length > 0 || initialImage) {
            updateBlogArticleMutation({
              variables: {
                input: blogInput,
                file: selectedFiles[0],
              },
              onError: () => {
                setIsSubmitting(true);
                showAlert({ message: 'Ошибка при редактировании блога!', variant: 'error' });
              },
              onCompleted: () => {
                showAlert({
                  message: 'Блог успешно изменён.',
                  variant: 'success',
                });
                navigate('/admin/blogs');
                setIsSubmitting(true);
              },
            });
          } else if (!selectedFiles[0] && !initialImage) {
            showAlert({
              message: 'Добавьте фото!',
              variant: 'warning',
            });
          } else {
            setIsSubmitting(true);
          }
        }}
      >
        {({ setFieldValue }) => (
          <Form>
            <>
              <Box sx={styles.headingBar}>
                <Text sx={styles.pageTitle}>Редактирование блога</Text>
                <Button
                  leftIcon={<FiSave />}
                  colorScheme='green'
                  type='submit'
                  disabled={imageDownloadInProcess}
                >
                  Сохранить изменения
                </Button>
              </Box>
              <Center flexDirection='column'>
                <Field name='title'>
                  {({ field, meta }) => (
                    <TextField
                      inputType='text'
                      placeholder='Название'
                      touched={meta.touched}
                      error={meta.error}
                      {...field}
                      required
                      wrapperProps={{
                        width: '47.5vw',
                        mb: '2vw',
                      }}
                    />
                  )}
                </Field>
                <div {...(selectedFiles.length > 0 || initialImage ? null : { ...getRootProps() })}>
                  <input {...getInputProps()} name='file' multiple={false} />
                  <AddFileWindow
                    disabled={selectedFiles.length > 0}
                    selectedFiles={selectedFiles}
                    onDeleteFile={onDeleteFile}
                    initialImage={initialImage}
                    setInitialImage={setInitialImage}
                    wrapperProps={{
                      width: '47.5vw',
                      height: '10vw',
                    }}
                  />
                </div>
                <Field name='descriptionText'>
                  {({ field, meta }) => (
                    <TextArea
                      placeholder='Описание блога'
                      touched={meta.touched}
                      error={meta.error}
                      {...field}
                      required
                      wrapperProps={{
                        width: '47.5vw',
                        mt: '2vw',
                      }}
                    />
                  )}
                </Field>
              </Center>
              <Center mt='2vw' mb='2vw'>
                <Field name='articleContent'>
                  {({ field }) => {
                    return (
                      <TextEditor
                        {...field}
                        setFieldValue={setFieldValue}
                        setImageDownloadInProcess={setImageDownloadInProcess}
                      />
                    );
                  }}
                </Field>
              </Center>
            </>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default EditBlog;
