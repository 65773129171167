import { Box } from '@chakra-ui/react';
import React from 'react';
import { Outlet } from 'react-router-dom';

import NavigationTab from '../../components/navigation-tab';
import { ROUTES } from '../../constants';
import { styles } from './styles';

const NAVIGATION_LIST = [
  {
    path: ROUTES.tours,
    name: 'Туры',
  },
  {
    path: ROUTES.blogs,
    name: 'Блоги',
  },
  {
    path: ROUTES.reviews,
    name: 'Отзывы',
  },
  {
    path: ROUTES.gallery,
    name: 'Галерея',
  },
];

const Admin = () => {
  return (
    <Box sx={styles.pageWrapper}>
      <NavigationTab options={NAVIGATION_LIST} />
      <Box>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Admin;
