import React from 'react';
import { useNavigate } from 'react-router-dom';

import { client } from '../../graphql/client';
import { actionTypes, authReducer } from './auth-context-reducer';

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(authReducer, {
    isAuth: false,
  });
  const navigate = useNavigate();
  //   console.log(localStorage.getItem('fdAdminToken'));
  //   console.log(sessionStorage.getItem('fdAdminToken'));

  React.useEffect(() => {
    if (
      !state.isAuth &&
      !sessionStorage.getItem('fdAdminToken') &&
      !localStorage.getItem('fdAdminToken')
    ) {
      navigate('/');
    }
    if (sessionStorage.getItem('fdAdminToken') || localStorage.getItem('fdAdminToken')) {
      dispatch({ type: actionTypes.SUCCESS_AUTH });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isAuth, localStorage, sessionStorage]);

  const handleSuccessAuth = () => {
    dispatch({ type: actionTypes.SUCCESS_AUTH });
  };

  const handleLogout = async () => {
    sessionStorage.removeItem('fdAdminToken');
    localStorage.removeItem('fdAdminToken');
    await client.clearStore();
    dispatch({ type: actionTypes.LOGOUT });
  };

  const value = {
    isAuth: state.isAuth,
    onSuccessAuth: handleSuccessAuth,
    onLogout: handleLogout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const AuthContext = React.createContext({
  isAuth: false,
  onSuccessAuth: () => {},
  onLogout: () => {},
});

export function useAuthContext() {
  const authContext = React.useContext(AuthContext);
  if (!authContext) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }
  return authContext;
}
