import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';

import { authLink } from './authLink';
import { errorLink } from './errorLink';
import { httpLink } from './httpLink';

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});
