import { Box, IconButton, Text } from '@chakra-ui/react';
import { FiLogOut } from 'react-icons/fi';

import { useAuth } from '../../hooks';
import { styles } from './styles';

const Header = () => {
  const { isAuth, onLogout } = useAuth();
  return (
    <Box sx={styles.headerWrapper}>
      <Text sx={styles.siteTitle}>freediving.space</Text>
      {isAuth ? <IconButton icon={<FiLogOut />} onClick={onLogout} /> : null}
    </Box>
  );
};

export default Header;
