export const styles = {
  pageWrapper: {
    width: '75vw',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 0.208vw 0.417vw rgba(0, 0, 0, 0.06)',
    overflowX: 'hidden',
  },
};
