export const styles = {
  tableWrapper: {
    marginTop: '1vw',
    width: '100%',
    marginBottom: '2vw',
  },
  headingBar: {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
    marginBottom: '1vw',
    marginTop: '5vw',
  },
  cellWithMenu: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: '0vw',
    justifyContent: 'flex-end',
  },
  menuBtn: {
    marginLeft: '1vw',
  },
  pageTitle: {
    fontSize: '2vw',
  },
  menuDeleteText: {
    color: 'crimson',
  },
  modalTextWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  loadingSpinner: {
    height: '5vw',
    width: '5vw',
    marginTop: '5vw',
  },
};
