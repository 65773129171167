import { Checkbox } from '@chakra-ui/react';

const DashboardCheckbox = ({ id, onChange, name, value, disabled }) => {
  return (
    <Checkbox
      id={id}
      checked={value}
      name={name}
      disabled={disabled}
      onChange={onChange}
      defaultChecked={value}
    />
  );
};

export default DashboardCheckbox;
