import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import React, { useState } from 'react';

import TextField from '../../../../components/text-field';
import { styles } from './styles';

const RangeTempPicker = () => {
  const { values } = useFormikContext();
  const [airTempError, setAirTempError] = useState(undefined);
  const [waterTempError, setWaterTempError] = useState(undefined);

  React.useEffect(() => {
    if (values.minAirTemperature <= values.maxAirTemperature) {
      setAirTempError(undefined);
    }
    if (values.minWaterTemperature <= values.maxWaterTemperature) {
      setWaterTempError(undefined);
    }
    if (values.maxAirTemperature !== '' && values.minAirTemperature > values.maxAirTemperature) {
      setAirTempError('Некорректная температура!');
    }
    if (
      values.maxWaterTemperature !== '' &&
      values.minWaterTemperature > values.maxWaterTemperature
    ) {
      setWaterTempError('Некорректная температура!');
    }
  }, [
    values.minAirTemperature,
    values.maxAirTemperature,
    values.minWaterTemperature,
    values.maxWaterTemperature,
  ]);

  return (
    <HStack spacing='2vw'>
      <Box sx={styles.tempDataContainer}>
        <Text>Температура воздуха:</Text>
        <Flex minH='3.5vw'>
          <HStack display='flex' alignItems='flex-start'>
            <Field name='minAirTemperature'>
              {({ field, meta }) => (
                <TextField
                  inputType='number'
                  placeholder='От'
                  touched={meta.touched}
                  error={airTempError || meta.error}
                  {...field}
                  required
                  wrapperProps={{
                    width: '10vw',
                  }}
                />
              )}
            </Field>
            <Field name='maxAirTemperature'>
              {({ field, meta }) => (
                <TextField
                  inputType='number'
                  placeholder='До'
                  touched={meta.touched}
                  error={airTempError || meta.error}
                  {...field}
                  required
                  wrapperProps={{
                    width: '10vw',
                  }}
                />
              )}
            </Field>
          </HStack>
        </Flex>
      </Box>
      <Box sx={styles.tempDataContainer}>
        <Text>Температура воды:</Text>
        <Flex minH='3.5vw'>
          <HStack display='flex' alignItems='flex-start'>
            <Field name='minWaterTemperature'>
              {({ field, meta }) => (
                <TextField
                  inputType='number'
                  placeholder='От'
                  touched={meta.touched}
                  error={waterTempError || meta.error}
                  {...field}
                  wrapperProps={{
                    width: '10vw',
                  }}
                />
              )}
            </Field>
            <Field name='maxWaterTemperature'>
              {({ field, meta }) => (
                <TextField
                  inputType='number'
                  placeholder='До'
                  touched={meta.touched}
                  error={waterTempError || meta.error}
                  {...field}
                  wrapperProps={{
                    width: '10vw',
                  }}
                />
              )}
            </Field>
          </HStack>
        </Flex>
      </Box>
    </HStack>
  );
};

export default RangeTempPicker;
