import { Box } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { styles } from './styles';

const NavigationTab = ({ options }) => {
  return (
    <Box sx={styles.navigationTabsWrapper}>
      {options.map((item, idx) => (
        <Link to={item.path} style={styles.tabContainer} key={idx}>
          <Box
            sx={{
              flexGrow: 1,
              flexBasis: 0,
              textAlign: 'center',
              borderLeft: idx > 0 ? '1px solid gray' : 'none',
              padding: '1rem',
            }}
          >
            {item.name}
          </Box>
        </Link>
      ))}
    </Box>
  );
};

export default NavigationTab;
