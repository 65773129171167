import { gql } from '@apollo/client';

import { GALLERY_FRAGMENT } from '../fragments/galleryFragment';

export const GET_PHOTOS = gql`
  query getPhotos($limit: Int, $offset: Int) {
    getPhotos(limit: $limit, offset: $offset) {
      ...GalleryFragment
    }
  }
  ${GALLERY_FRAGMENT}
`;
