import { IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { AiOutlineSetting } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

import DeleteReviewModal from './delete-review-modal';
import { styles } from './styles';

const TableMenu = ({ reviewData }) => {
  const navigate = useNavigate();
  return (
    <Menu>
      <MenuButton as={IconButton} icon={<AiOutlineSetting />} sx={styles.menuBtn} />
      <MenuList>
        {/* eslint-disable-next-line no-underscore-dangle */}
        <MenuItem onClick={() => navigate(`edit/${reviewData?._id}`)}>Редактировать</MenuItem>
        <DeleteReviewModal reviewData={reviewData} />
      </MenuList>
    </Menu>
  );
};

export default TableMenu;
