import { gql } from '@apollo/client';

export const UPDATE_BLOG_ARTICLE = gql`
  mutation ($input: UpdateBlogArticleInput!, $file: Upload) {
    updateBlogArticle(input: $input, file: $file) {
      _id
      photoName
      title
      description
      content
      createdAt
      photoUrl
    }
  }
`;
