import { gql } from '@apollo/client';

export const ADD_REVIEW = gql`
  mutation ($input: AddReviewInput!) {
    addReview(input: $input) {
      _id
      userFirstName
      userLastName
      tourName
      text
      createdAt
    }
  }
`;
