import { Box, HStack, Text } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import React from 'react';

import TextField from '../../../../components/text-field';
import { styles } from './styles';

const RangeDatePicker = () => {
  const { values } = useFormikContext();
  const [error, setError] = React.useState(undefined);

  React.useEffect(() => {
    if (values.tripStartDate < values.tripEndDate) {
      setError(undefined);
    }
    if (values.tripEndDate !== '' && values.tripStartDate > values.tripEndDate) {
      setError('Неправильная последовательность!');
    }
    if (values.tripEndDate !== '' && values.tripStartDate === values.tripEndDate) {
      setError('Даты начала и окончания тура не должны совпадать');
    }
  }, [values.tripStartDate, values.tripEndDate]);

  return (
    <HStack spacing='2vw' mb='1vw' display='flex' alignItems='flex-start' minH='4.7vw'>
      <Box sx={styles.tempDataContainer}>
        <Text>Дата старта путешествия</Text>
        <Field name='tripStartDate'>
          {({ field, meta }) => (
            <TextField
              inputType='date'
              touched={meta.touched}
              error={error || meta.error}
              {...field}
              required
              wrapperProps={{
                width: '15vw',
              }}
            />
          )}
        </Field>
      </Box>
      <Box sx={styles.tempDataContainer}>
        <Text>Дата конца путешествия</Text>
        <Field name='tripEndDate'>
          {({ field, meta }) => (
            <TextField
              inputType='date'
              touched={meta.touched}
              error={error || meta.error}
              {...field}
              required
              wrapperProps={{
                width: '15vw',
              }}
            />
          )}
        </Field>
      </Box>
    </HStack>
  );
};

export default RangeDatePicker;
