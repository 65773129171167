const invalidMessage = (value) => `Invalid ${value} format`;

export const EMAIL_MESSAGE = invalidMessage('email');
export const USERNAME_MESSAGE = invalidMessage('username');
export const PHONE_MESSAGE = invalidMessage('p');

export const REQUIRE_MESSAGE = 'Это поле обязательно';

export const INCORRECT_SYMBOL_MESSAGE = 'Использован запрещённый символ';

export const TOUR_SHORT_DESCRIPTION_MESSAGE = 'Описание должно состоять минимум из 20 символов!';
export const TOUR_LONG_DESCRIPTION_MESSAGE = 'Максимальная длина описания тура - 2000 символов';
export const TOUR_LONG_SUMMARY_MESSAGE =
  'Максимальная длина краткого описания тура - 700 символов!';
export const TOUR_SHORT_DESTINATION_MESSAGE = 'Поле должно содержать минимум 5 символов';
export const TOUR_LONG_DESTINATION_MESSAGE = 'Максимальная длинна - 200 символов';
export const TOUR_INCORRECT_PEOPLE_EMOTIONS = 'От 5 до 45 символов!';
export const TOUR_SHORT_TITLE_MESSAGE = 'Заголовок должнен состоять минимум из 5 символов!';
export const BLOG_SHORT_DESCRIPTION_MESSAGE = 'Описание должно состоять минимум из 20 символов!';
export const BLOG_SHORT_TITLE_MESSAGE = 'Заголовок должнен состоять минимум из 5 символов!';
export const REVIEW_SHORT_TEXT_MESSAGE = 'Описание должно состоять минимум из 40 символов!';
export const FIRST_NAME_SHORT_TEXT_MESSAGE = 'Имя должно состоять минимум  из 1 символа!';
export const FIRST_NAME_LONG_TEXT_MESSAGE = 'Имя не должно превышать 25 символов!!';
export const LAST_NAME_SHORT_TEXT_MESSAGE = 'Фамилия должна состоять минимум  из 1 символа!';
export const LAST_NAME_LONG_TEXT_MESSAGE = 'Фамилия не должна превышать 25 символов!';
export const REVIEW_LONG_TEXT_MESSAGE = 'Отзыв не должен превышать 300 символов!';
export const INCORRECT_MIN_AIR_TEMPERATURE = 'Не меньше -55 градусов!';
export const INCORRECT_MAX_AIR_TEMPERATURE = 'Не больше 100 градусов!';
export const INCORRECT_MIN_WATER_TEMPERATURE = 'Не меньше 1 градуса!';
export const INCORRECT_MAX_WATER_TEMPERATURE = 'Не больше 100 градусов!';
export const INCORRECT_PRICE_VALUE = 'Некорректная цена!';
export const INCORRECT_PLACES_COUNT = 'Минимум один человек!';
export const INCORRECT_OCCUPIED_PLACES_COUNT = 'Отрицательное число!';

export const PHONE_SHORT_MESSAGE = (long) => `Номер должен содержать минимум ${long} символов`;
export const PHONE_LONG_MESSAGE = (long) => `Номер не должен превышать ${long} символов`;
export const PASSWORD_SHORT_MESSAGE = (long) => `Пароль должен содержать минимум ${long} символов`;
export const PASSWORD_LONG_MESSAGE = (long) => `Пароль не должен превышать ${long} символов`;
