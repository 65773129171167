import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

import TextEditor from '../../../../components/text-editor';
import { styles } from './styles';

const BenefitsContent = ({ benefits, setBenefits, setImageDownloadInProcess }) => {
  const handleContentChange = (content) => {
    setBenefits(content);
  };

  return (
    <Flex sx={styles.daysContentWrapper}>
      <Text sx={styles.daysContentTitleText}>Дополнительная информация:</Text>
      <Flex flexDirection='column'>
        <TextEditor
          name='benefits'
          value={benefits}
          onBlur={() => {}}
          setFieldValue={(name, value) => handleContentChange(value)}
          limit={500}
          setImageDownloadInProcess={setImageDownloadInProcess}
        />
      </Flex>
    </Flex>
  );
};

export default BenefitsContent;
