import { useApolloClient } from '@apollo/client';
import { Box, Button, Heading, Stack, Text } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import DashboardCheckbox from '../../components/checkbox';
import TextField from '../../components/text-field';
import { SIGN_IN } from '../../graphql/queries';
import { useAuth } from '../../hooks';
import { styles } from './styles';

const Home = () => {
  const navigate = useNavigate();
  const client = useApolloClient();
  const { onSuccessAuth, isAuth } = useAuth();

  React.useEffect(() => {
    if (isAuth) {
      navigate('/admin/tours');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);
  return (
    <Box sx={styles.homePageWrapper}>
      <Box sx={styles.loginWindow}>
        <Heading sx={styles.welcomingTitle}>Добро пожаловать!</Heading>
        <Formik
          initialValues={{
            login: '',
            password: '',
            rememberMe: false,
          }}
          onSubmit={(values, actions) => {
            client
              .query({
                query: SIGN_IN,
                variables: {
                  login: values.login,
                  password: values.password,
                },
              })
              .then((response) => {
                if (values.rememberMe) {
                  localStorage?.setItem('fdAdminToken', response.data.signin.Authorization);
                }
                sessionStorage?.setItem('fdAdminToken', response.data.signin.Authorization);
                onSuccessAuth();
                navigate('/admin/reviews');
              })
              .catch((e) => {
                if (e.message === 'Неправильный пароль') {
                  actions.setErrors({
                    password: e.message,
                  });
                }
                if (e.message === 'Введенный вами логин не существует') {
                  actions.setErrors({
                    login: e.message,
                  });
                }
              });
          }}
        >
          <Form>
            <Stack spacing='1vw'>
              <Field name='login'>
                {({ field, meta }) => (
                  <TextField
                    inputType='text'
                    placeholder='Логин'
                    touched={meta.touched}
                    error={meta.error}
                    required
                    {...field}
                    wrapperProps={{
                      width: '22vw',
                    }}
                  />
                )}
              </Field>
              <Field name='password'>
                {({ field, meta }) => (
                  <TextField
                    inputType='password'
                    placeholder='Пароль'
                    touched={meta.touched}
                    error={meta.error}
                    required
                    {...field}
                    wrapperProps={{
                      width: '22vw',
                    }}
                  />
                )}
              </Field>
              <Box sx={styles.rememberMeWrapper}>
                <Field type='checkbox' name='rememberMe'>
                  {({ field }) => <DashboardCheckbox {...field} />}
                </Field>
                <Text sx={styles.rememberMeText}>Запомнить меня</Text>
              </Box>
              <Button sx={styles.loginBtn} type='submit' variant='solid'>
                Вход
              </Button>
            </Stack>
          </Form>
        </Formik>
      </Box>
    </Box>
  );
};

export default Home;
