import { gql } from '@apollo/client';

export const GET_REVIEW = gql`
  query getReview($id: String!) {
    getReview(id: $id) {
      _id
      userFirstName
      userLastName
      tourName
      text
      createdAt
    }
  }
`;
