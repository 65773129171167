import { Box, Button, Center, Spinner, Text } from '@chakra-ui/react';
import React from 'react';
import { FiPlusCircle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import SearchBar from '../../../components/search-bar';
import { useGetToursQuery } from '../../../hooks';
import ToursTable from './components/tours-table';
import { styles } from './styles';

const ToursList = () => {
  const [filter, setFilter] = React.useState({
    searchString: '',
  });

  const { data: tours, loading } = useGetToursQuery({
    variables: {
      name: filter.field,
    },
  });

  const navigate = useNavigate();
  return (
    <Box sx={styles.toursListWrapper}>
      <Box sx={styles.headingBar}>
        <Text sx={styles.pageTitle}>Туры</Text>
        <Button leftIcon={<FiPlusCircle />} onClick={() => navigate('add')}>
          Добавить тур
        </Button>
      </Box>
      <SearchBar placeholder={'Поиск'} onChangeField={setFilter} />

      {loading ? (
        <Center>
          <Spinner
            thickness='0.3vw'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.200'
            sx={styles.loadingSpinner}
          />
        </Center>
      ) : (
        <>
          <ToursTable
            data={tours?.getTours.upcomingTours?.concat(tours?.getTours.completedTours)}
          />
        </>
      )}
    </Box>
  );
};

export default ToursList;
