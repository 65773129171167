export const styles = {
  reviewsListWrapper: {
    marginTop: '1.7vw',
    paddingX: '1vw',
  },
  headingBar: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1vw',
  },
  overlay: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '9999',
  },
  overlayText: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  pageTitle: {
    fontWeight: 600,
    fontSize: '1vw',
  },
  addReviewBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#27485C',
    borderRadius: '0.625vw',
    padding: '0.4vw',
    color: '#fff',
    fontSize: '0.8vw',
    cursor: 'pointer',
    _hover: {
      opacity: 0.9,
      transition: '0.3s',
    },
  },
  loadingSpinner: {
    height: '5vw',
    width: '5vw',
    marginTop: '5vw',
  },
  checkboxBlockWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  myTravelText: {
    marginLeft: '1vw',
  },
};
