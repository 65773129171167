import { Flex, Icon, IconButton, Image, Text, Wrap } from '@chakra-ui/react';
import React from 'react';
import { BsFillImageFill } from 'react-icons/bs';
import { FiTrash2 } from 'react-icons/fi';

import { MAX_FILE_SIZE_BYTES, MAX_FILE_SIZE_MB } from '../../../../constants';
import { useAlert, useFileUpload } from '../../../../hooks';
import { useImageUploadMutation } from '../../../../hooks/useMutations';
import { styles } from './styles';

const SliderImageLoader = ({ id, imageList, setImageList }) => {
  const { selectedFiles, onResetFiles, getRootProps, getInputProps } = useFileUpload();
  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);
  const [imageUploadMutation, { loading }] = useImageUploadMutation();
  const { showAlert } = useAlert();

  const handleUploadImage = () => {
    if (selectedFiles.length !== 0) {
      const file = selectedFiles[0];

      if (file.size > MAX_FILE_SIZE_BYTES) {
        showAlert({
          message: `Размер файла превышает допустимый лимит в ${MAX_FILE_SIZE_MB} Мб`,
          variant: 'error',
        });
        onResetFiles();
        return;
      }

      imageUploadMutation({
        variables: {
          file: selectedFiles[0],
        },
        onCompleted: (response) => {
          setImageList([...imageList, response.uploadImage.imageName]);
          onResetFiles();
        },
        onError: (error) => {
          if (error.message.includes('File size exceeds the limit')) {
            showAlert({
              message: `Размер файла превышает допустимый лимит в ${MAX_FILE_SIZE_MB} Мб`,
              variant: 'error',
            });
          } else {
            showAlert({
              message: 'Ошибка при загрузке фото!',
              variant: 'error',
            });
          }
        },
      });
    }
  };

  const handleAddFile = () => {
    const file = selectedFiles[0];
    if (file.size > MAX_FILE_SIZE_BYTES) {
      showAlert({
        message: `Размер файла превышает допустимый лимит в ${MAX_FILE_SIZE_MB} Мб`,
        variant: 'error',
      });
      onResetFiles();
      return;
    }
    setImageList([...imageList, selectedFiles[0]]);
  };

  const handleDeleteFile = (idx) => {
    const result = imageList;
    result.splice(idx, 1);
    setImageList(result);
    forceUpdate();
  };

  React.useEffect(() => {
    if (selectedFiles.length > 0 && id) {
      handleUploadImage();
    }
    if (selectedFiles.length > 0) {
      handleAddFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiles]);

  return (
    <Flex sx={styles.loaderWrapper}>
      <Text sx={styles.loaderTitleText}>Фото слайдера:</Text>
      <Wrap sx={styles.imageLoaderWrapper} spacing='2.3vw'>
        {imageList?.map((image, idx) => (
          <Flex sx={styles.imageWrapper} key={idx}>
            <IconButton
              onClick={() => handleDeleteFile(idx)}
              sx={styles.deleteBtn}
              icon={<FiTrash2 size='50%' />}
            />
            <Image
              src={
                id && !loading
                  ? `${process.env?.REACT_APP_BASE_URL}/storage/images/${image}`
                  : image?.preview
              }
              alt='image'
              sx={styles.imageStyle}
            />
          </Flex>
        ))}
        <div
          {...(imageList.length === 5 ? null : { ...getRootProps() })}
          style={{ opacity: imageList.length === 5 ? 0.5 : 'unset' }}
        >
          <input {...getInputProps()} name='file' multiple={false} />
          <Flex sx={styles.addImageBtnWrapper}>
            <Flex sx={styles.imageAddingDescriptionWrapper}>
              <Icon as={BsFillImageFill} sx={styles.iconWrapper} />
              <Text sx={styles.addInstructionTitle}>Перетащите изображение или нажмите сюда</Text>
              <Text sx={styles.addInstructionSubtitle}>
                Поддерживаемые форматы: .jpg, .png, .gif Размер файла - до 10 Мб
              </Text>
            </Flex>
          </Flex>
        </div>
      </Wrap>
    </Flex>
  );
};

export default SliderImageLoader;
