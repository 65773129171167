export const styles = {
  headerWrapper: {
    width: '100%',
    height: '3.5vw',
    background: '#27485C',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingX: '1vw',
  },
  siteTitle: {
    color: '#fff',
    fontFamily: 'Dela Gothic One',
    fontSize: '1vw',
  },
};
