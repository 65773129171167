import { gql } from '@apollo/client';

export const SWAP_PHOTO_ORDER = gql`
  mutation SwapPhotoOrder($input: SwapPhotoOrderInput!) {
    swapPhotoOrder(input: $input) {
      _id
      photoUrl
      index
    }
  }
`;
