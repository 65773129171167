import { gql } from '@apollo/client';

export const IMAGE_UPLOAD = gql`
  mutation ($file: Upload!) {
    uploadImage(file: $file) {
      imageName
      imageUrl
    }
  }
`;
