import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect, useRef } from 'react';

import { useImageUploadMutation } from '../../hooks';

const TextEditor = ({
  idx,
  name,
  value,
  onBlur,
  setFieldValue,
  includeImage = true,
  daysContentList,
  setDaysContentList,
  setImageDownloadInProcess,
}) => {
  const editorRef = useRef(null);
  const [imageUploadMutation, { loading }] = useImageUploadMutation();

  const handleChangeValue = (content) => {
    const editedDayContent = daysContentList;
    editedDayContent[idx - 1] = content;
    setDaysContentList(editedDayContent);
  };

  useEffect(() => {
    if (editorRef.current) {
      const editor = editorRef.current;

      if (loading) {
        setImageDownloadInProcess(true);
        editor.setProgressState(true);
        editor.execCommand('mceButtonDisable', false, { name: 'save' });
      } else {
        editor.setProgressState(false);
        editor.execCommand('mceButtonEnable', false, { name: 'save' });
        setImageDownloadInProcess(false);
      }
    }
  }, [loading]);

  const imageUploadHandler = async (blobInfo) => {
    return new Promise((resolve, reject) => {
      const file = blobInfo.blob();
      imageUploadMutation({
        variables: {
          file,
        },
        onCompleted: (response) => {
          const imageUrl = `${process.env.REACT_APP_BASE_URL}/storage/images/${response.uploadImage.imageName}`;
          resolve(imageUrl);
        },
        onError: (error) => {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(`Upload failed: ${error.message}`);
        },
      });
    });
  };

  return (
    <>
      <Editor
        id={idx?.toString()}
        apiKey={process.env.REACT_APP_TINY_MCE_EDITOR_API_KEY}
        name={name}
        value={value}
        onBlur={onBlur}
        initialValue={idx ? daysContentList[idx - 1] : null}
        onEditorChange={(content) => {
          if (!idx) {
            setFieldValue(name, content);
          }
          if (idx) {
            handleChangeValue(content);
          }
        }}
        // eslint-disable-next-line no-return-assign
        onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          height: 500,
          valid_children: '-p[img],h1[img],h2[img],h3[img],h4[img],+div[img],span[img]',
          width: '47.5vw',
          menubar: false,
          plugins: ['advlist', 'autolink', 'lists', 'image', 'media', 'wordcount'],
          advlist_bullet_styles: 'square',
          file_picker_types: 'image',
          automatic_uploads: true,
          paste_data_images: false,
          images_upload_handler: imageUploadHandler,
          file_picker_callback: (cb) => {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');

            input.addEventListener('change', (e) => {
              const file = e.target.files[0];

              const reader = new FileReader();
              reader.addEventListener('load', () => {
                const id = `blobid ${new Date().getTime()}`;
                /* eslint-disable-next-line no-undef */
                const blobCache = tinymce?.activeEditor?.editorUpload?.blobCache;
                const base64 = reader.result.split(',')[1];
                const blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);

                cb(blobInfo.blobUri(), { title: file.name });
              });
              reader.readAsDataURL(file);
            });

            input.click();
          },
          toolbar: [
            'undo redo',
            'bold h3',
            'bullist blockquote',
            includeImage && 'image',
            'paragraph',
          ]
            .filter(Boolean)
            .join(' | '),
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
        }}
      />
    </>
  );
};

export default TextEditor;
