import { HStack } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import React from 'react';

import TextField from '../../../../components/text-field';

const GroupCountPicker = () => {
  const { values } = useFormikContext();
  const [error, setError] = React.useState(undefined);

  React.useEffect(() => {
    if (
      values.totalPlacesInGroup !== '' &&
      values.totalPlacesInGroup < values.occupiedPlacesInGroup
    ) {
      setError('Некорректный ввод!');
    }
    if (values.totalPlacesInGroup >= values.occupiedPlacesInGroup) {
      setError(undefined);
    }
  }, [values]);

  return (
    <HStack spacing='2vw' display='flex' alignItems='flex-start' minH='3.5vw'>
      <Field name='totalPlacesInGroup'>
        {({ field, meta }) => (
          <TextField
            inputType='number'
            placeholder='Мест в группе'
            touched={meta.touched}
            error={error || meta.error}
            {...field}
            required
            wrapperProps={{
              width: '10vw',
            }}
          />
        )}
      </Field>
      <Field name='occupiedPlacesInGroup'>
        {({ field, meta }) => (
          <TextField
            inputType='number'
            placeholder='Мест свободно'
            touched={meta.touched}
            error={error || meta.error}
            {...field}
            required
            wrapperProps={{
              width: '10vw',
            }}
          />
        )}
      </Field>
    </HStack>
  );
};

export default GroupCountPicker;
