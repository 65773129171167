import { SnackbarProvider } from 'notistack';
import React from 'react';

export const AlertProvider = ({ children }) => {
  const providerRef = React.useRef();

  const handleShowAlert = ({ message = 'title', variant = 'success' }) => {
    providerRef.current.enqueueSnackbar(message, {
      variant,
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };

  return (
    <AlertContext.Provider
      value={{
        showAlert: handleShowAlert,
      }}
    >
      <SnackbarProvider
        ref={providerRef}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        maxSnack={3}
      >
        {children}
      </SnackbarProvider>
    </AlertContext.Provider>
  );
};

export const AlertContext = React.createContext({
  showAlert: () => {},
});

export const useAlertContext = () => {
  const context = React.useContext(AlertContext);
  if (!context) {
    throw new Error('useAlertContext must be used within a AlertProvider');
  }
  return context;
};
