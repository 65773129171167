import { gql } from '@apollo/client';

import { FULL_TOUR_FRAGMENT } from '../fragments';

export const GET_TOUR = gql`
  ${FULL_TOUR_FRAGMENT}
  query getTour($id: String!) {
    getTour(id: $id) {
      ...FullFragmentTour
    }
  }
`;
