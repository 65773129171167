import * as yup from 'yup';

import * as MESSAGES from '../messages';

export const tourSchema = yup.object().shape({
  title: yup.string().min(5, MESSAGES.TOUR_SHORT_TITLE_MESSAGE),
  descriptionText: yup
    .string()
    .min(20, MESSAGES.TOUR_SHORT_DESCRIPTION_MESSAGE)
    .max(2000, MESSAGES.TOUR_LONG_DESCRIPTION_MESSAGE),
  shortDescriptionText: yup.string().max(700, MESSAGES.TOUR_LONG_SUMMARY_MESSAGE),
  destination: yup
    .string()
    .min(5, MESSAGES.TOUR_SHORT_DESTINATION_MESSAGE)
    .max(200, MESSAGES.TOUR_LONG_DESTINATION_MESSAGE),
  price: yup.number().min(1, MESSAGES.INCORRECT_PRICE_VALUE),
  minAirTemperature: yup
    .number()
    .min(-55, MESSAGES.INCORRECT_MIN_AIR_TEMPERATURE)
    .max(100, MESSAGES.INCORRECT_MAX_AIR_TEMPERATURE),
  maxAirTemperature: yup
    .number()
    .min(-55, MESSAGES.INCORRECT_MIN_AIR_TEMPERATURE)
    .max(100, MESSAGES.INCORRECT_MAX_AIR_TEMPERATURE),
  minWaterTemperature: yup
    .number()
    .min(1, MESSAGES.INCORRECT_MIN_WATER_TEMPERATURE)
    .max(100, MESSAGES.INCORRECT_MAX_WATER_TEMPERATURE),
  maxWaterTemperature: yup
    .number()
    .min(1, MESSAGES.INCORRECT_MIN_WATER_TEMPERATURE)
    .max(100, MESSAGES.INCORRECT_MAX_WATER_TEMPERATURE),
  peopleEmotions: yup
    .string()
    .min(5, MESSAGES.TOUR_INCORRECT_PEOPLE_EMOTIONS)
    .max(45, MESSAGES.TOUR_INCORRECT_PEOPLE_EMOTIONS),
  totalPlacesInGroup: yup.number().min(1, MESSAGES.INCORRECT_PLACES_COUNT),
  occupiedPlacesInGroup: yup.number().min(0, MESSAGES.INCORRECT_OCCUPIED_PLACES_COUNT),
  additionalInfo: yup.string().min(20, MESSAGES.TOUR_SHORT_DESCRIPTION_MESSAGE),
});
