import { gql } from '@apollo/client';

export const FULL_TOUR_FRAGMENT = gql`
  fragment FullFragmentTour on Tour {
    _id
    name
    description
    shortDescription
    photoNames
    date {
      from
      to
    }
    difficultyLevel
    airTemperature {
      from
      to
    }
    waterTemperature {
      from
      to
    }
    typeOfResidence
    isPossibleWithChildren
    price {
      currency
      value
    }
    totalNumberOfPlacesInGroup
    numberOfOccupiedPlacesInGroup
    days {
      dayName
      dayContent
    }
    urlPhotos
    destination
    benefits
    supplements
  }
`;

export const PARTIAL_TOUR_FRAGMENT = gql`
  fragment PartialFragmentTour on Tour {
    _id
    name
    description
    date {
      from
      to
    }
    difficultyLevel
    isPossibleWithChildren
    totalNumberOfPlacesInGroup
    numberOfOccupiedPlacesInGroup
    price {
      currency
      value
    }
  }
`;
