import { gql } from '@apollo/client';

import { PARTIAL_BLOG_ARTICLE } from '../fragments';

export const GET_BLOG_ARTICLES = gql`
  ${PARTIAL_BLOG_ARTICLE}
  query getBlogArticles($limit: Int, $offset: Int, $name: String) {
    getBlogArticles(limit: $limit, offset: $offset, name: $name) {
      ...PartialFragmentArticle
    }
  }
`;
