/* eslint-disable no-underscore-dangle */
import { Box, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

import { styles } from './styles';
import TableMenu from './table-menu';

const BlogsTable = ({ data }) => {
  return (
    <TableContainer overflowX='hidden'>
      <Table sx={styles.tableWrapper} variant='striped' colorScheme='blue'>
        <Thead sx={styles.tableHeading}>
          <Tr sx={styles.tableRow}>
            <Th>Название</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.map((blog) => (
            <Tr sx={styles.tableRow} key={blog._id}>
              <Td>{blog?.title}</Td>
              <Td>
                <Box sx={styles.cellWithMenu}>
                  <TableMenu blogData={blog} />
                </Box>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default BlogsTable;
