import { gql } from '@apollo/client';

export const UPDATE_TOUR = gql`
  mutation ($input: UpdateTourInput!) {
    updateTour(input: $input) {
      _id
      name
      destination
      description
      photoNames
      urlPhotos
      date {
        from
        to
      }
      difficultyLevel
      airTemperature {
        from
        to
      }
      waterTemperature {
        from
        to
      }
      typeOfResidence
      isPossibleWithChildren
      price {
        currency
        value
      }
      totalNumberOfPlacesInGroup
      numberOfOccupiedPlacesInGroup
      days {
        dayName
        dayContent
      }
      benefits
    }
  }
`;
