import { useMutation } from '@apollo/client';

import * as MUTATIONS from '../graphql/mutations';

export const useAddBlogArticleMutation = (options) => {
  return useMutation(MUTATIONS.ADD_BLOG_ARTICLE, options);
};

export const useUpdateBlogArticleMutation = (options) => {
  return useMutation(MUTATIONS.UPDATE_BLOG_ARTICLE, options);
};

export const useRemoveBlogArticleMutation = (options) => {
  return useMutation(MUTATIONS.REMOVE_BLOG_ARTICLE, options);
};

export const useAddReviewMutation = (options) => {
  return useMutation(MUTATIONS.ADD_REVIEW, options);
};

export const useUpdateReviewMutation = (options) => {
  return useMutation(MUTATIONS.UPDATE_REVIEW, options);
};

export const useRemoveReviewMutation = (options) => {
  return useMutation(MUTATIONS.REMOVE_REVIEW, options);
};

export const useImageUploadMutation = (options) => {
  return useMutation(MUTATIONS.IMAGE_UPLOAD, { fetchPolicy: 'no-cache', ...options });
};

export const useCreateTourMutation = (options) => {
  return useMutation(MUTATIONS.CREATE_TOUR, options);
};

export const useRemoveTourMutation = (options) => {
  return useMutation(MUTATIONS.REMOVE_TOUR, options);
};

export const useUpdateTourMutation = (options) => {
  return useMutation(MUTATIONS.UPDATE_TOUR, options);
};

export const useRemovePhotoMutation = (options) => {
  return useMutation(MUTATIONS.REMOVE_PHOTO, options);
};
