import * as yup from 'yup';

import * as MESSAGES from '../messages';
import { REVIEW_LONG_TEXT_MESSAGE } from '../messages';

export const reviewSchema = yup.object().shape({
  reviewText: yup
    .string()
    .min(40, MESSAGES.REVIEW_SHORT_TEXT_MESSAGE)
    .max(300, REVIEW_LONG_TEXT_MESSAGE),
  tourName: yup.string().min(10, MESSAGES.REVIEW_SHORT_TEXT_MESSAGE),
  name: yup
    .string()
    .min(1, MESSAGES.FIRST_NAME_SHORT_TEXT_MESSAGE)
    .max(25, MESSAGES.FIRST_NAME_LONG_TEXT_MESSAGE),
  surname: yup
    .string()
    .min(1, MESSAGES.LAST_NAME_SHORT_TEXT_MESSAGE)
    .max(25, MESSAGES.LAST_NAME_LONG_TEXT_MESSAGE),
});
