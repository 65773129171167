import { FormControl, Select } from '@chakra-ui/react';

const DropdownMenu = ({
  options = [],
  placeholder,
  onChange,
  required,
  name,
  value,
  onBlur,
  touched,
  error,
  disabled,
  wrapperProps,
}) => {
  return (
    <FormControl isInvalid={Boolean(error && touched)} {...wrapperProps}>
      <Select
        required={required}
        placeholder={placeholder}
        disabled={disabled}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        border='0.052vw solid'
      >
        {options.map((option) => (
          <option value={option?.value} key={option?.value}>
            {option?.name}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropdownMenu;
