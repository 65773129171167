export const styles = {
  inputWrapper: {
    fontSize: '1vw',
  },
  groupStyle: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputStyle: {
    border: '0.052vw solid',
    fontSize: '1vw',
  },
  changeVisibilityBtn: {
    backgroundColor: 'transparent',
  },
};
