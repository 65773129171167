import { setContext } from '@apollo/client/link/context';

import { getToken } from '../utils';

export const authLink = setContext(async (_, { headers }) => {
  const cookiesToken = getToken();
  const token =
    cookiesToken || localStorage.getItem('fdAdminToken') || sessionStorage.getItem('fdAdminToken');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'Apollo-Require-Preflight': 'true',
    },
  };
});
