import { Box } from '@chakra-ui/react';

import Header from '../header';
import { styles } from './styles';

const Layout = ({ children }) => {
  return (
    <Box>
      <Header />
      <main style={styles.layoutWrapper}>{children}</main>
    </Box>
  );
};

export default Layout;
