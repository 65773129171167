export const styles = {
  homePageWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  welcomingTitle: {
    fontSize: '2.4vw',
    marginBottom: '1.250vw',
  },
  loginWindow: {
    borderRadius: '0.625vw',
    padding: '0.833vw',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    top: '40%',
    boxShadow: '0 0.7vw 0.8vw rgba(0, 0, 0, 0.06)',
  },
  rememberMeWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  rememberMeText: {
    marginLeft: '0.5vw',
  },
};
