import { ApolloProvider } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';

import Layout from './components/layout';
import Router from './components/router';
import { AlertProvider, AuthProvider } from './context';
import { client } from './graphql/client';

const App = () => {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <AuthProvider>
          <AlertProvider>
            <ChakraProvider>
              <Layout>
                <Router />
              </Layout>
            </ChakraProvider>
          </AlertProvider>
        </AuthProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
};

export default App;
