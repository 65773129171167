export const styles = {
  photosListWrapper: {
    padding: '2vw',
  },
  headingBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2vw',
  },
  pageTitle: {
    fontSize: '2vw',
  },
  loadingSpinner: {
    marginTop: '2vw',
  },
  tableWrapper: {
    marginTop: '1vw',
    width: '100%',
    marginBottom: '2vw',
  },
  tableRow: {},
  cellWithMenu: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  menuDeleteText: {
    color: 'crimson',
  },
  modalTextWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
};
