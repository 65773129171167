export const styles = {
  navigationTabsWrapper: {
    width: '75vw',
    display: 'flex',
    boxShadow: '0 0.208vw 0.417vw rgba(0, 0, 0, 0.06)',
  },
  tabContainer: {
    flexGrow: 1,
    background: '#f1f1f1',
    height: '2.5vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    color: '1',
    flexDirection: 'row',
    borderBottom: '0.052vw solid #f1f1f1',
  },
};
