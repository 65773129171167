import { useMutation } from '@apollo/client';
import { Box, Button, Center, Icon, Spinner, Text, Tooltip } from '@chakra-ui/react';
import { useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import { MdOutlineSwapHoriz } from 'react-icons/md';

import { SWAP_PHOTO_ORDER } from '../../../../graphql/mutations';
import PhotosTable from './photos-table';
import { styles } from './styles';

const PhotoGallery = ({
  photos,
  loading,
  refetch,
  handleSelectedPhotos,
  selectedPhotos,
  resetSelectedPhotos,
}) => {
  const [selectingPhotoModeIsOn, setSelectingPhotoModeIsOn] = useState(false);

  const generateLoadingMessage = (length) => {
    if (length === 1) return 'Выберите ещё одно фото';
    if (length === 0) return 'Выберите две фотографии';
    return '';
  };

  const [swapPhotoOrder] = useMutation(SWAP_PHOTO_ORDER, {
    onCompleted: () => refetch(),
  });

  const handleSwap = async (id1, id2) => {
    try {
      await swapPhotoOrder({
        variables: {
          input: { id1, id2 },
        },
      });
      resetSelectedPhotos();
      setSelectingPhotoModeIsOn(false);
    } catch (error) {
      console.error('Smth went wrong', error);
    }
  };

  return (
    <Box sx={styles.tableWrapper}>
      <Box sx={styles.headingBar}>
        <Text sx={styles.pageTitle}>Галерея</Text>
        {photos?.getPhotos.length >= 2 && !selectingPhotoModeIsOn && (
          <Tooltip
            label='Выберите 2 фотографии, которые хотите поменять местами и сохраните изменения'
            hasArrow
            arrowSize={15}
          >
            <Button onClick={() => setSelectingPhotoModeIsOn((prevState) => !prevState)}>
              <Icon as={MdOutlineSwapHoriz} boxSize={8} />
            </Button>
          </Tooltip>
        )}
        {selectingPhotoModeIsOn &&
          (selectedPhotos.length < 2 ? (
            <Box display='flex' gap='5px'>
              <Button
                isLoading
                loadingText={generateLoadingMessage(selectedPhotos.length)}
                variant='outline'
                colorScheme='blue'
              >
                Поменять местами
              </Button>
              <Tooltip label='Отменить выбор фото' hasArrow arrowSize={15}>
                <Button
                  onClick={() => {
                    resetSelectedPhotos();
                    setSelectingPhotoModeIsOn(false);
                  }}
                >
                  <Icon as={IoCloseOutline} boxSize={8} />
                </Button>
              </Tooltip>
            </Box>
          ) : (
            <Button
              variant='solid'
              colorScheme='blue'
              onClick={() =>
                selectedPhotos.length === 2 && handleSwap(selectedPhotos[0], selectedPhotos[1])
              }
            >
              Поменять местами
            </Button>
          ))}
      </Box>

      {loading ? (
        <Center>
          <Spinner
            thickness='0.3vw'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.200'
            sx={styles.loadingSpinner}
          />
        </Center>
      ) : (
        <PhotosTable
          data={photos?.getPhotos}
          refetch={refetch}
          handleSelectedPhotos={handleSelectedPhotos}
          selectingPhotoModeIsOn={selectingPhotoModeIsOn}
          selectedPhotos={selectedPhotos}
        />
      )}
    </Box>
  );
};

export default PhotoGallery;
