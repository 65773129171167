import { Box, Button, Center, HStack, Spinner, Text } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { FiSave } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom';

import TextArea from '../../../../components/text-area';
import TextField from '../../../../components/text-field';
import { useAlert, useGetReviewQuery } from '../../../../hooks';
import { useUpdateReviewMutation } from '../../../../hooks/useMutations';
import { reviewSchema } from '../../../../validation/schemas';
import { styles } from '../styles';

const EditReview = () => {
  const [updateReviewMutation] = useUpdateReviewMutation();
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: review, loading } = useGetReviewQuery({
    variables: {
      id,
    },
  });
  return (
    <Box sx={styles.reviewsListWrapper}>
      {!loading ? (
        <Formik
          initialValues={{
            name: review?.getReview.userFirstName,
            surname: review?.getReview.userLastName,
            tourName: review?.getReview.tourName,
            reviewText: review?.getReview.text,
          }}
          enableReinitialize
          validationSchema={reviewSchema}
          onSubmit={(values) => {
            updateReviewMutation({
              variables: {
                input: {
                  id,
                  userFirstName: values.name,
                  userLastName: values.surname,
                  tourName: values.tourName,
                  text: values.reviewText,
                },
              },
              onError: () =>
                showAlert({ message: 'Ошибка при редактировании отзыва!', variant: 'error' }),
              onCompleted: () => {
                showAlert({
                  message: 'Отзыв успешно изменён.',
                  variant: 'success',
                });
                navigate('/admin/reviews');
              },
            });
          }}
        >
          <Form>
            <Box sx={styles.headingBar}>
              <Text sx={styles.pageTitle}>Изменить отзыв</Text>
              <Button leftIcon={<FiSave />} colorScheme='green' type='submit'>
                Сохранить изменения
              </Button>
            </Box>
            <Center flexDirection='column'>
              <HStack spacing='1.5vw'>
                <Field name='name'>
                  {({ field, meta }) => (
                    <TextField
                      inputType='text'
                      placeholder='Имя'
                      touched={meta.touched}
                      error={meta.error}
                      {...field}
                      required
                      wrapperProps={{
                        width: '23vw',
                      }}
                    />
                  )}
                </Field>
                <Field name='surname'>
                  {({ field, meta }) => (
                    <TextField
                      inputType='text'
                      placeholder='Фамилия'
                      touched={meta.touched}
                      error={meta.error}
                      {...field}
                      required
                      wrapperProps={{
                        width: '23vw',
                      }}
                    />
                  )}
                </Field>
              </HStack>
              <HStack spacing='1.5vw' mt='2vw' mb='2vw'>
                <Field name='tourName'>
                  {({ field, meta }) => (
                    <TextField
                      placeholder='Название тура'
                      touched={meta.touched}
                      error={meta.error}
                      {...field}
                      wrapperProps={{
                        width: '47.5vw',
                      }}
                    />
                  )}
                </Field>
              </HStack>
              <Field name='reviewText'>
                {({ field, meta }) => (
                  <TextArea
                    placeholder='Текст отзыва'
                    touched={meta.touched}
                    error={meta.error}
                    {...field}
                    required
                    wrapperProps={{
                      width: '47.5vw',
                      mt: '2vw',
                    }}
                  />
                )}
              </Field>
            </Center>
          </Form>
        </Formik>
      ) : (
        <Center>
          <Spinner
            thickness='0.3vw'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.200'
            sx={styles.loadingSpinner}
          />
        </Center>
      )}
    </Box>
  );
};

export default EditReview;
