import { useQuery } from '@apollo/client';

import * as QUERIES from '../graphql/queries';

export const useGetReviewsQuery = (options) => {
  return useQuery(QUERIES.GET_REVIEWS, {
    fetchPolicy: 'no-cache',
    ...options,
  });
};

export const useGetReviewQuery = (options) => {
  return useQuery(QUERIES.GET_REVIEW, {
    fetchPolicy: 'no-cache',
    ...options,
  });
};

export const useGetBlogArticlesQuery = (options) => {
  return useQuery(QUERIES.GET_BLOG_ARTICLES, {
    fetchPolicy: 'no-cache',
    ...options,
  });
};

export const useGetBlogArticleQuery = (options) => {
  return useQuery(QUERIES.GET_BLOG_ARTICLE, {
    fetchPolicy: 'no-cache',
    ...options,
  });
};

export const useSignInQuery = (options) => {
  return useQuery(QUERIES.SIGN_IN, {
    fetchPolicy: 'no-cache',
    ...options,
  });
};

export const useGetToursQuery = (options) => {
  return useQuery(QUERIES.GET_TOURS, {
    fetchPolicy: 'no-cache',
    ...options,
  });
};

export const useGetTourQuery = (options) => {
  return useQuery(QUERIES.GET_TOUR, {
    fetchPolicy: 'no-cache',
    ...options,
  });
};

export const useGetPhotosQuery = (options) => {
  return useQuery(QUERIES.GET_PHOTOS, {
    fetchPolicy: 'no-cache',
    ...options,
  });
};
