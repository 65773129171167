import {
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import React from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

import { styles } from './styles';

const TextField = ({
  inputType = 'text',
  placeholder,
  onChange,
  required,
  name,
  value,
  onBlur,
  touched,
  error,
  disabled,
  min,
  max,
  wrapperProps,
}) => {
  const [currentInputType, setCurrentInputType] = React.useState(inputType);
  const [passwordVisibility, setPasswordVisiility] = React.useState(false);

  const handleChangePasswordVisibility = () => {
    setPasswordVisiility(!passwordVisibility);
    if (currentInputType === 'password') {
      setCurrentInputType('text');
    }
    if (currentInputType !== 'password') {
      setCurrentInputType('password');
    }
  };

  return (
    <FormControl isInvalid={Boolean(error && touched)} sx={styles.inputWrapper} {...wrapperProps}>
      <InputGroup sx={styles.groupStyle}>
        <Input
          required={required}
          placeholder={placeholder}
          type={currentInputType}
          disabled={disabled}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          sx={styles.inputStyle}
          min={min}
          max={max}
        />
        {inputType === 'password' ? (
          <InputRightElement>
            <IconButton
              icon={passwordVisibility ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              onClick={handleChangePasswordVisibility}
              sx={styles.changeVisibilityBtn}
            />
          </InputRightElement>
        ) : null}
        {error && touched ? <FormErrorMessage>{error}</FormErrorMessage> : null}
      </InputGroup>
    </FormControl>
  );
};

export default TextField;
