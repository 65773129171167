import { Box, Button, Center, HStack, Spinner, Text } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { FiSave } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom';

import DashboardCheckbox from '../../../../components/checkbox';
import DropdownMenu from '../../../../components/dropdown-menu';
import TextArea from '../../../../components/text-area';
import TextField from '../../../../components/text-field';
import { DIFFICULTY_LEVELS, PRICE_CURRENCY, RESIDENCE_VARIANTS } from '../../../../constants';
import { useAlert, useGetTourQuery, useUpdateTourMutation } from '../../../../hooks';
import { tourSchema } from '../../../../validation/schemas';
import BenefitsContent from '../components/benefits-content';
import DaysContent from '../components/days-content';
import GroupCountPicker from '../components/group-count-picker';
import RangeDatePicker from '../components/range-date-picker';
import RangeTempPicker from '../components/range-temp-picker';
import SliderImageLoader from '../components/slider-image-loader';
import { styles } from '../styles';

const EditTour = () => {
  const [imageList, setImageList] = React.useState([]);
  const [daysContentList, setDaysContentList] = React.useState([]);
  const [daysContentListError, setDaysContentListError] = useState('');
  const [imageDownloadInProcess, setImageDownloadInProcess] = useState(false);
  const [daysNamesList, setDaysNamesList] = React.useState([]);
  const [updateTourMutation] = useUpdateTourMutation();
  const [benefits, setBenefits] = React.useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { id } = useParams();
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const { data: tour, loading } = useGetTourQuery({
    variables: {
      id,
    },
    onCompleted: (response) => {
      setImageList(response.getTour.photoNames);
      const result = [];
      const dayNames = [];
      response.getTour.days.map((day) => {
        dayNames.push(day.dayName);
        result.push(day.dayContent);
        return null;
      });
      setDaysContentList(result);
      setDaysNamesList(dayNames);

      setBenefits(response.getTour.benefits);
    },
  });

  const dayContentExistanceCheck = () => {
    let isValid = true;
    daysContentList.forEach((day, idx) => {
      if (day === '') {
        setDaysContentListError(`Заполните день ${idx + 1} путешестия!`);
        isValid = false;
      }
    });
    return isValid;
  };

  const parseDayContent = () => {
    const result = [];
    daysContentList.map((dayContent, idx) => {
      result.push({ dayName: daysNamesList[idx], dayContent });
      return null;
    });
    return result;
  };

  return (
    <Box sx={styles.toursListWrapper}>
      {isSubmitting && (
        <Box sx={styles.overlay}>
          <Text sx={styles.overlayText} color='blue.200'>
            Сохраняем изменения...
          </Text>
          <Spinner
            thickness='0.3vw'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.200'
            sx={styles.loadingSpinner}
          />
        </Box>
      )}
      {!loading ? (
        <Formik
          initialValues={{
            title: tour?.getTour.name,
            descriptionText: tour?.getTour.description,
            shortDescriptionText: tour?.getTour.shortDescription,
            difficultyLevel: tour?.getTour.difficultyLevel,
            diveDifficultyLevel: tour?.getTour.diveDifficultyCategory,
            residenceType: tour?.getTour.typeOfResidence,
            price: tour?.getTour.price.value,
            currency: tour?.getTour.price.currency,
            childrenAllowed: tour?.getTour.isPossibleWithChildren,
            tripStartDate: DateTime.fromISO(tour?.getTour.date.from).toFormat('yyyy-MM-dd'),
            tripEndDate: DateTime.fromISO(tour?.getTour.date.to).toFormat('yyyy-MM-dd'),
            minAirTemperature: tour?.getTour.airTemperature.from,
            maxAirTemperature: tour?.getTour.airTemperature.to,
            minWaterTemperature: tour?.getTour.waterTemperature.from || undefined,
            maxWaterTemperature: tour?.getTour.waterTemperature.to || undefined,
            totalPlacesInGroup: tour?.getTour.totalNumberOfPlacesInGroup,
            occupiedPlacesInGroup: tour?.getTour.numberOfOccupiedPlacesInGroup,
            destination: tour?.getTour.destination,
            benefits: tour?.getTour.benefits,
          }}
          enableReinitialize
          validationSchema={tourSchema}
          onSubmit={(values) => {
            setIsSubmitting(true);
            dayContentExistanceCheck();
            if (imageList.length < 2) {
              showAlert({
                message: 'У слайдера должно быть минимум 2 фото!',
                variant: 'warning',
              });
            }
            if (daysContentList.length < 1) {
              showAlert({
                message: 'Добавьте хотя бы один день путешествия!',
                variant: 'warning',
              });
            }
            if (!dayContentExistanceCheck()) {
              showAlert({
                message: `${daysContentListError}`,
                variant: 'warning',
              });
              setIsSubmitting(false);
              return;
            }
            if (daysContentList.length > 0 && imageList.length >= 2) {
              updateTourMutation({
                variables: {
                  input: {
                    id,
                    name: values.title,
                    description: values.descriptionText,
                    shortDescription: values.shortDescriptionText,
                    date: {
                      from: values.tripStartDate,
                      to: values.tripEndDate,
                    },
                    difficultyLevel: values.difficultyLevel,
                    airTemperature: {
                      from: parseInt(values.minAirTemperature.toFixed(), 10),
                      to: parseInt(values.maxAirTemperature.toFixed(), 10),
                    },
                    waterTemperature: {
                      from: values.maxWaterTemperature
                        ? parseInt(values.minWaterTemperature.toFixed(), 10)
                        : undefined,
                      to: values.maxWaterTemperature
                        ? parseInt(values.maxWaterTemperature.toFixed(), 10)
                        : undefined,
                    },
                    typeOfResidence: values.residenceType,
                    isPossibleWithChildren: values.childrenAllowed,
                    price: {
                      currency: values.currency,
                      value: values.price,
                    },
                    totalNumberOfPlacesInGroup: parseInt(values.totalPlacesInGroup.toFixed(), 10),
                    numberOfOccupiedPlacesInGroup: parseInt(
                      values.occupiedPlacesInGroup.toFixed(),
                      10,
                    ),
                    days: parseDayContent(),
                    photoNames: imageList,
                    destination: values.destination,
                    disclaimer: values.disclaimer,
                    benefits,
                  },
                },
                onCompleted: () => {
                  showAlert({
                    message: 'Тур успешно изменён.',
                    variant: 'success',
                  });
                  navigate('/admin/tours');
                  setIsSubmitting(false);
                },
                onError: () => {
                  showAlert({
                    message: 'Ошибка при редактировании тура!',
                    variant: 'error',
                  });
                  setIsSubmitting(false);
                },
              });
            } else {
              setIsSubmitting(false);
            }
          }}
        >
          <Form>
            <Box sx={styles.headingBar}>
              <Text sx={styles.pageTitle}>Добавление тура</Text>
              <Button
                leftIcon={<FiSave />}
                colorScheme='green'
                type='submit'
                disabled={imageDownloadInProcess}
              >
                Сохранить изменения
              </Button>
            </Box>
            <Center flexDirection='column' alignItems={'flex-start'}>
              <Field name='title'>
                {({ field, meta }) => (
                  <TextField
                    inputType='text'
                    placeholder='Название'
                    touched={meta.touched}
                    error={meta.error}
                    {...field}
                    required
                    wrapperProps={{
                      width: '47.5vw',
                    }}
                  />
                )}
              </Field>
              <HStack mt='2vw'>
                <Field name='destination'>
                  {({ field, meta }) => (
                    <TextField
                      inputType='text'
                      placeholder='Местоположение'
                      touched={meta.touched}
                      error={meta.error}
                      {...field}
                      required
                      wrapperProps={{
                        width: '47.5vw',
                      }}
                    />
                  )}
                </Field>
              </HStack>
              <HStack mt='2vw' mb='1vw' spacing='2vw'>
                <Field name='difficultyLevel'>
                  {({ field, meta }) => (
                    <DropdownMenu
                      placeholder='Сложность'
                      touched={meta.touched}
                      options={DIFFICULTY_LEVELS}
                      error={meta.error}
                      {...field}
                      required
                      wrapperProps={{
                        width: '11vw',
                      }}
                    />
                  )}
                </Field>

                <Box sx={styles.checkboxWrapper}>
                  <Field type='checkbox' name='childrenAllowed'>
                    {({ field }) => <DashboardCheckbox {...field} />}
                  </Field>
                  <Text ml='0.5vw'>Можно с детьми</Text>
                </Box>
              </HStack>
              <HStack
                mt='1vw'
                mb='-1vw'
                spacing='2vw'
                display='flex'
                alignItems='flex-start'
                minH='4.7vw'
              >
                <Field name='residenceType'>
                  {({ field, meta }) => (
                    <DropdownMenu
                      placeholder='Тип проживания'
                      touched={meta.touched}
                      options={RESIDENCE_VARIANTS}
                      error={meta.error}
                      {...field}
                      required
                      wrapperProps={{
                        width: '15vw',
                      }}
                    />
                  )}
                </Field>
                <Field name='price'>
                  {({ field, meta }) => (
                    <TextField
                      inputType='number'
                      placeholder='Цена'
                      touched={meta.touched}
                      error={meta.error}
                      {...field}
                      required
                      wrapperProps={{
                        width: '10vw',
                      }}
                    />
                  )}
                </Field>
                <Field name='currency'>
                  {({ field, meta }) => (
                    <DropdownMenu
                      placeholder='Валюта'
                      touched={meta.touched}
                      options={PRICE_CURRENCY}
                      error={meta.error}
                      {...field}
                      required
                      wrapperProps={{
                        width: '12vw',
                      }}
                    />
                  )}
                </Field>
              </HStack>
              <GroupCountPicker />
              <RangeTempPicker />
              <RangeDatePicker />
              <Field name='descriptionText'>
                {({ field, meta }) => (
                  <TextArea
                    placeholder='Описание тура'
                    touched={meta.touched}
                    error={meta.error}
                    {...field}
                    required
                    wrapperProps={{
                      width: '47.5vw',
                      mb: '0.5vw',
                    }}
                  />
                )}
              </Field>
              <Field name='shortDescriptionText'>
                {({ field, meta }) => (
                  <TextArea
                    placeholder='Краткое описание тура'
                    touched={meta.touched}
                    error={meta.error}
                    {...field}
                    wrapperProps={{
                      width: '47.5vw',
                    }}
                  />
                )}
              </Field>
              <SliderImageLoader id={id} imageList={imageList} setImageList={setImageList} />
              <DaysContent
                daysContentList={daysContentList}
                setDaysContentList={setDaysContentList}
                daysNamesList={daysNamesList}
                setDaysNamesList={setDaysNamesList}
                setImageDownloadInProcess={setImageDownloadInProcess}
              />
              <BenefitsContent
                benefits={benefits}
                setBenefits={setBenefits}
                setImageDownloadInProcess={setImageDownloadInProcess}
              />
            </Center>
          </Form>
        </Formik>
      ) : (
        <Center>
          <Spinner
            thickness='0.3vw'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.200'
            sx={styles.loadingSpinner}
          />
        </Center>
      )}
    </Box>
  );
};

export default EditTour;
