export const styles = {
  tableWrapper: {
    marginTop: '1vw',
    width: '100%',
    marginBottom: '2vw',
  },
  tableRow: {},
  cellWithMenu: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: '0vw',
    justifyContent: 'flex-end',
  },
  menuBtn: {
    marginLeft: '1vw',
  },
  menuDeleteText: {
    color: 'crimson',
  },
  modalTextWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  loaderWrapper: {
    flexDirection: 'column',
    marginTop: '1.5vw',
  },
  loaderTitleText: {
    marginBottom: '0.5vw',
    fontSize: '1vw',
    marginLeft: '0.5vw',
  },
  imageLoaderWrapper: {
    width: '47.5vw',
    marginBottom: '2vw',
  },
  imageWrapper: {
    borderRadius: '0.625vw',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    width: '14.3vw',
    height: '14.3vw',
  },
  imageStyle: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  deleteBtn: {
    position: 'absolute',
    width: '4vw',
    height: '4vw',
    zIndex: 5,
    color: 'crimson',
  },
  addImageBtnWrapper: {
    borderRadius: '0.625vw',
    alignItems: 'center',
    justifyContent: 'center',
    width: '14.3vw',
    height: '14.3vw',
    background: 'gray.100',
    cursor: 'pointer',
  },
  imageAddingDescriptionWrapper: {
    border: '0.052vw dashed #0066CC',
    borderRadius: '0.625vw',
    width: '95%',
    height: '95%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconWrapper: {
    width: '10%',
    height: '10%',
    marginTop: '20%',
  },
  addInstructionTitle: {
    marginTop: '7%',
    fontSize: '0.6vw',
    textAlign: 'center',
  },
  addInstructionSubtitle: {
    fontSize: '0.61vw',
    textAlign: 'center',
    marginTop: '1%',
    color: 'gray',
  },
  daysContentWrapper: {
    flexDirection: 'column',
    marginTop: '1.5vw',
    marginBottom: '2vw',
  },
  daysContentTitleText: {
    marginBottom: '0.5vw',
    fontSize: '1vw',
    marginLeft: '0.5vw',
  },
  dayContentBtnWrapper: {
    width: '47.5vw',
    height: '10vw',
    borderRadius: '0.625vw',
    background: 'gray.100',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginTop: '1vw',
  },
  dayContentBtn: {
    border: '0.052vw dashed #0066CC',
    borderRadius: '0.625vw',
    width: '98%',
    height: '94%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dayContentBtnIcon: {
    width: '25%',
    height: '25%',
    marginTop: '3%',
  },
  dayContentBtnText: {
    marginTop: '1%',
    color: 'gray',
  },
  deleteDayBtn: {
    width: '2vw',
    height: '2vw',
    color: 'crimson',
  },
  editableDayName: {
    display: 'flex',
    alignItems: 'center',
  },
  editDayNameBtn: {
    width: '2vw',
    height: '2vw',
    marginLeft: '1vw',
  },
  dayNameInput: {
    height: '2vw',
  },
};
