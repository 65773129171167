import { Box, Button, Center, HStack, Text } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { FiPlusCircle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import TextArea from '../../../components/text-area';
import TextField from '../../../components/text-field';
import { useAlert } from '../../../hooks';
import { useAddReviewMutation } from '../../../hooks/useMutations';
import { reviewSchema } from '../../../validation/schemas';
import { styles } from './styles';

const AddReview = () => {
  const [addReviewMutation] = useAddReviewMutation();
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  return (
    <Box sx={styles.reviewsListWrapper}>
      <Formik
        initialValues={{
          name: '',
          surname: '',
          tourName: '',
          reviewText: '',
        }}
        validationSchema={reviewSchema}
        onSubmit={(values) => {
          addReviewMutation({
            variables: {
              input: {
                userFirstName: values.name,
                userLastName: values.surname,
                tourName: values.tourName,
                text: values.reviewText,
              },
            },
            onCompleted: () => {
              showAlert({
                message: 'Отзыв успешно добавлен.',
                variant: 'success',
              });
              navigate('/admin/reviews');
            },
            onError: () => {
              showAlert({
                message: 'Ошибка при создании отзыва!',
                variant: 'error',
              });
            },
          });
        }}
      >
        <Form>
          <Box sx={styles.headingBar}>
            <Text sx={styles.pageTitle}>Добавление отзыва</Text>
            <Button leftIcon={<FiPlusCircle />} colorScheme='green' type='submit'>
              Добавить отзыв
            </Button>
          </Box>
          <Center flexDirection='column'>
            <HStack spacing='1.5vw'>
              <Field name='name'>
                {({ field, meta }) => (
                  <TextField
                    inputType='text'
                    placeholder='Имя'
                    touched={meta.touched}
                    error={meta.error}
                    {...field}
                    required
                    wrapperProps={{
                      width: '23vw',
                    }}
                  />
                )}
              </Field>
              <Field name='surname'>
                {({ field, meta }) => (
                  <TextField
                    inputType='text'
                    placeholder='Фамилия'
                    touched={meta.touched}
                    error={meta.error}
                    {...field}
                    required
                    wrapperProps={{
                      width: '23vw',
                    }}
                  />
                )}
              </Field>
            </HStack>
            <HStack spacing='1.5vw' mt='2vw' mb='2vw'>
              <Field name='tourName'>
                {({ field, meta }) => (
                  <TextField
                    placeholder='Название тура'
                    touched={meta.touched}
                    error={meta.error}
                    {...field}
                    wrapperProps={{
                      width: '47.5vw',
                    }}
                  />
                )}
              </Field>
            </HStack>
            <Field name='reviewText'>
              {({ field, meta }) => (
                <TextArea
                  placeholder='Текст отзыва'
                  touched={meta.touched}
                  error={meta.error}
                  {...field}
                  required
                  wrapperProps={{
                    width: '47.5vw',
                    mt: '2vw',
                  }}
                />
              )}
            </Field>
          </Center>
        </Form>
      </Formik>
    </Box>
  );
};

export default AddReview;
