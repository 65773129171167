import { FormControl, FormErrorMessage, Textarea } from '@chakra-ui/react';
import React from 'react';

const TextArea = ({
  placeholder,
  onChange,
  required,
  name,
  value,
  onBlur,
  touched,
  error,
  disabled,
  wrapperProps,
}) => {
  return (
    <FormControl isInvalid={Boolean(error && touched)} {...wrapperProps}>
      <Textarea
        required={required}
        placeholder={placeholder}
        disabled={disabled}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        border='0.052vw solid'
      />
      {error && touched ? <FormErrorMessage>{error}</FormErrorMessage> : null}
    </FormControl>
  );
};

export default TextArea;
