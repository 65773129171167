import { gql } from '@apollo/client';

import { PARTIAL_TOUR_FRAGMENT } from '../fragments';

export const GET_TOURS = gql`
  ${PARTIAL_TOUR_FRAGMENT}
  query getTours($name: String) {
    getTours(name: $name) {
      upcomingTours {
        ...PartialFragmentTour
      }
      completedTours {
        ...PartialFragmentTour
      }
    }
  }
`;
