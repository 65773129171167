import { Route, Routes } from 'react-router-dom';

import Admin from '../../pages/admin';
import EditBlog from '../../pages/admin/blogs/[id]/edit-blog';
import AddBlog from '../../pages/admin/blogs/add-blog';
import BlogsList from '../../pages/admin/blogs/blogs-list';
import AddPhotos from '../../pages/admin/gallery/add-photos';
import EditReview from '../../pages/admin/reviews/[id]/edit-review';
import AddReview from '../../pages/admin/reviews/add-review';
import ReviewsList from '../../pages/admin/reviews/reviews-list';
import EditTour from '../../pages/admin/tours/[id]/edit-tour';
import AddTour from '../../pages/admin/tours/add-tour';
import ToursList from '../../pages/admin/tours/tours-list';
import Home from '../../pages/home';

const Router = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='*' element={<div>Not found!</div>} />
      <Route path='admin/' element={<Admin />}>
        <Route path='tours' element={<ToursList />} />
        <Route path='tours/add' element={<AddTour />} />
        <Route path='tours/edit/:id' element={<EditTour />} />
        <Route path='blogs' element={<BlogsList />} />
        <Route path='blogs/add' element={<AddBlog />} />
        <Route path='blogs/edit/:id' element={<EditBlog />} />
        <Route path='reviews' element={<ReviewsList />} />
        <Route path='reviews/add' element={<AddReview />} />
        <Route path='reviews/edit/:id' element={<EditReview />} />
        <Route path='gallery' element={<AddPhotos />} />
      </Route>
    </Routes>
  );
};

export default Router;
