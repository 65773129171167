import { gql } from '@apollo/client';

export const UPDATE_REVIEW = gql`
  mutation ($input: UpdateReviewInput!) {
    updateReview(input: $input) {
      _id
      userFirstName
      userLastName
      tourName
      text
      createdAt
    }
  }
`;
