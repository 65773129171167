import { gql } from '@apollo/client';

import { FULL_TOUR_FRAGMENT } from '../fragments';

export const CREATE_TOUR = gql`
  ${FULL_TOUR_FRAGMENT}
  mutation ($input: CreateTourInput!, $files: [Upload!]!) {
    createTour(input: $input, files: $files) {
      ...FullFragmentTour
    }
  }
`;
