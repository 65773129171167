import { FormControl, IconButton, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import React from 'react';
import { AiOutlineSearch } from 'react-icons/ai';

const SearchBar = ({
  placeholder,
  onChangeField,
  required,
  name,
  value,
  onBlur,
  touched,
  error,
  disabled,
  wrapperProps,
}) => {
  const handleChangeInput = (event) => {
    return onChangeField({
      field: event.target.value,
    });
  };
  return (
    <FormControl isInvalid={Boolean(error && touched)} {...wrapperProps}>
      <InputGroup>
        <Input
          required={required}
          placeholder={placeholder}
          type={'text'}
          disabled={disabled}
          name={name}
          value={value}
          onChange={handleChangeInput}
          onBlur={onBlur}
        />
        <InputRightElement>
          <IconButton icon={<AiOutlineSearch />} variant='link' />
        </InputRightElement>
      </InputGroup>
    </FormControl>
  );
};

export default SearchBar;
