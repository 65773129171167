/* eslint-disable no-underscore-dangle */
import { Box, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';

import { styles } from './styles';
import TableMenu from './table-menu';

const ReviewsTable = ({ data }) => {
  return (
    <TableContainer overflowX='hidden'>
      <Table sx={styles.tableWrapper} variant='striped' colorScheme='blue'>
        <Thead sx={styles.tableHeading}>
          <Tr sx={styles.tableRow}>
            <Th>Имя автора</Th>
            <Th>Тур</Th>
            <Th isNumeric>ID</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.map((review) => (
            <Tr sx={styles.tableRow} key={review._id}>
              <Td>
                {review.userFirstName}&nbsp;{review.userLastName}
              </Td>
              <Td>{review.tourName}</Td>
              <Td>
                <Box sx={styles.cellWithMenu}>
                  <Text>{review._id}</Text>
                  <TableMenu reviewData={review} />
                </Box>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default ReviewsTable;
