import { gql } from '@apollo/client';

export const GET_REVIEWS = gql`
  query getReviewsWithCount($limit: Int, $offset: Int, $fullName: String) {
    getReviewsWithCount(limit: $limit, offset: $offset, fullName: $fullName) {
      reviews {
        _id
        userFirstName
        userLastName
        tourName
        text
      }
      count
    }
  }
`;
