import { gql } from '@apollo/client';

export const ADD_PHOTO = gql`
  mutation AddPhoto($file: Upload!) {
    addPhoto(file: $file) {
      _id
      photoUrl
      createdAt
    }
  }
`;
