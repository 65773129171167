export const styles = {
  fileWindowWrapper: {
    borderRadius: '0.625vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    zIndex: -1,
  },
  imageAddingDescriptionWrapper: {
    border: '0.052vw dashed #0066CC',
    borderRadius: '0.625vw',
    width: '85%',
    height: '95%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
  },
  iconWrapper: {
    width: '20%',
    height: '20%',
    marginTop: '1%',
  },
  addInstructionTitle: {
    marginTop: '7%',
  },
  addInstructionSubtitle: {
    textAlign: 'center',
    maxWidth: '45%',
    marginTop: '1%',
    color: 'gray',
  },
  imageWrapper: {
    width: 'inherit',
    height: 'inherit',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 4,
  },
  deleteBtn: {
    position: 'absolute',
    width: '5vw',
    height: '5vw',
    zIndex: 5,
    color: 'crimson',
  },
  imageView: {
    objectFit: 'cover',
    width: '100%',
  },
};
