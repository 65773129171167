import { useMutation } from '@apollo/client';
import { Box, IconButton, Image, SimpleGrid } from '@chakra-ui/react';
import { useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';

import { REMOVE_PHOTO } from '../../../../graphql/mutations';

const PhotosTable = ({
  data,
  refetch,
  handleSelectedPhotos,
  selectingPhotoModeIsOn,
  selectedPhotos,
}) => {
  const [hovered, setHovered] = useState(null);
  const [removePhoto] = useMutation(REMOVE_PHOTO, {
    onCompleted: () => refetch(),
  });

  const handleDeletePhoto = async (id) => {
    try {
      await removePhoto({
        variables: {
          input: { id },
        },
      });
    } catch (error) {
      console.error('Error deleting photo:', error);
    }
  };

  return (
    <Box
      width='100%'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <SimpleGrid columns={[2, null, 4]} spacing='1rem' width='100%'>
        {data?.map(({ _id, photoUrl }) => (
          <Box
            key={_id}
            position='relative'
            paddingBottom='80%'
            onMouseEnter={() => setHovered(_id)}
            onMouseLeave={() => setHovered(null)}
            onClick={() => selectingPhotoModeIsOn && handleSelectedPhotos(_id)}
          >
            <Image
              src={photoUrl}
              alt={`Photo ${_id}`}
              borderRadius='12px'
              objectFit='cover'
              sx={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                transition: 'filter 0.3s',
                cursor: selectingPhotoModeIsOn && 'pointer',
                border:
                  selectingPhotoModeIsOn && selectedPhotos.includes(_id)
                    ? '4px solid lightBlue'
                    : '',
                filter: hovered === _id && !selectingPhotoModeIsOn ? 'blur(1px)' : 'none',
              }}
            />
            {hovered === _id && !selectingPhotoModeIsOn && (
              <IconButton
                icon={<FiTrash2 size='80%' />}
                aria-label='Delete photo'
                position='absolute'
                cursor='pointer'
                top='50%'
                left='50%'
                transform='translate(-50%, -50%)'
                onClick={() => handleDeletePhoto(_id)}
                sx={{
                  bg: 'white',
                  color: 'red.400',
                  _hover: { color: 'red.600' },
                }}
              />
            )}
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default PhotosTable;
