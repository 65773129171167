import { useMutation } from '@apollo/client';
import { Box, Button, Center, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { FiPlusCircle } from 'react-icons/fi';

import AddFileWindow from '../../../components/add-file-window';
import { MAX_FILE_SIZE_BYTES, MAX_FILE_SIZE_MB } from '../../../constants';
import { ADD_PHOTO } from '../../../graphql/mutations';
import { useAlert, useFileUpload, useGetPhotosQuery } from '../../../hooks';
import PhotoGallery from './components/photo-gallery';
import { styles } from './styles';

const AddPhotos = () => {
  const { selectedFiles, onDeleteFile, getRootProps, getInputProps, onResetFiles } =
    useFileUpload();
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const { showAlert } = useAlert();
  const { data: photos, loading, refetch } = useGetPhotosQuery();
  const [addPhoto] = useMutation(ADD_PHOTO, {
    onCompleted: () => {
      refetch();
      onResetFiles();
    },
  });

  const handleSelectedPhotos = (id) => {
    if (selectedPhotos.includes(id)) {
      setSelectedPhotos((prevState) => prevState.filter((item) => item !== id));
    } else if (selectedPhotos.length < 2) {
      setSelectedPhotos((prevState) => [...prevState, id]);
    }
  };

  const resetSelectedPhotos = () => {
    setSelectedPhotos([]);
  };

  return (
    <Box sx={styles.photosListWrapper}>
      <Formik
        initialValues={{}}
        onSubmit={async (values, actions) => {
          if (selectedFiles.length === 0) {
            showAlert({
              message: 'Добавьте фото!',
              variant: 'warning',
            });
          } else if (selectedFiles[0].size > MAX_FILE_SIZE_BYTES) {
            showAlert({
              message: `Размер файла превышает допустимый лимит в ${MAX_FILE_SIZE_MB} Мб`,
              variant: 'error',
            });
            onResetFiles();
          } else {
            try {
              await addPhoto({
                variables: {
                  file: selectedFiles[0],
                },
              });
              showAlert({
                message: 'Фото добавлено.',
                variant: 'success',
              });
              await refetch();
            } catch (error) {
              showAlert({
                message: 'Ошибка при добавлении фото!',
                variant: 'error',
              });
            }
          }
          actions.setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Box sx={styles.headingBar}>
              <Text sx={styles.pageTitle}>Добавление фото</Text>
              <Button
                leftIcon={<FiPlusCircle />}
                colorScheme='green'
                type='submit'
                isLoading={isSubmitting}
              >
                Добавить фото
              </Button>
            </Box>
            <Center flexDirection='column'>
              <div {...getRootProps()}>
                <input {...getInputProps()} name='file' multiple={false} />
                <AddFileWindow
                  selectedFiles={selectedFiles}
                  onDeleteFile={onDeleteFile}
                  wrapperProps={{
                    width: '47.5vw',
                    height: '10vw',
                  }}
                />
              </div>
            </Center>
          </Form>
        )}
      </Formik>
      <PhotoGallery
        photos={photos}
        loading={loading}
        refetch={refetch}
        handleSelectedPhotos={handleSelectedPhotos}
        resetSelectedPhotos={resetSelectedPhotos}
        selectedPhotos={selectedPhotos}
      />
    </Box>
  );
};

export default AddPhotos;
