export const DIFFICULTY_LEVELS = [
  {
    value: 'BEGINNER',
    name: 'Для начинающих',
  },
  {
    value: 'AMATEUR',
    name: 'Для любителей',
  },
  {
    value: 'ADVANCED',
    name: 'Для продвинутых',
  },
  {
    value: 'EXPERT',
    name: 'Для экспертов',
  },
];

export const DIVE_DIFFICULTY_LEVELS = [
  {
    value: '1',
    name: 1,
  },
  {
    value: '2',
    name: 2,
  },
  {
    value: '3',
    name: 3,
  },
  {
    value: '4',
    name: 4,
  },
  {
    value: '5',
    name: 5,
  },
];

export const PRICE_CURRENCY = [
  {
    value: 'USD',
    name: 'USD',
  },
  {
    value: 'EUR',
    name: 'EUR',
  },
  {
    value: 'RUB',
    name: 'RUB',
  },
];

export const RESIDENCE_VARIANTS = [
  {
    value: 'NONE',
    name: 'Без гостиницы',
  },
  {
    value: 'REGULAR_HOTEL_WITH_NO_MEALS',
    name: 'Гостиница без питания',
  },
  {
    value: 'REGULAR_HOTEL_WITH_MEALS',
    name: 'Гостиница с питанием',
  },
  {
    value: 'COMFORTABLE_HOTEL_WITH_NO_MEALS',
    name: 'Комфортная гостиница без питания',
  },
  {
    value: 'COMFORTABLE_HOTEL_WITH_MEALS',
    name: 'Комфортная гостиница с питанием',
  },
];

export const MAX_FILE_SIZE_MB = 10;
export const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;
