import { IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { AiOutlineSetting } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

import DeleteReviewModal from './delete-blog-modal';
import { styles } from './styles';

const TableMenu = ({ blogData }) => {
  const navigate = useNavigate();
  return (
    <Menu>
      <MenuButton as={IconButton} icon={<AiOutlineSetting />} sx={styles.menuBtn} />
      <MenuList>
        {/* eslint-disable-next-line no-underscore-dangle */}
        <MenuItem onClick={() => navigate(`edit/${blogData?._id}`)}>Редактировать</MenuItem>
        <DeleteReviewModal blogData={blogData} />
      </MenuList>
    </Menu>
  );
};

export default TableMenu;
