import {
  Box,
  Button,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { GET_REVIEWS } from '../../../../graphql/queries';
import { useAlert } from '../../../../hooks';
import { useRemoveReviewMutation } from '../../../../hooks/useMutations';
import { styles } from './styles';

const DeleteReviewModal = ({ reviewData }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { showAlert } = useAlert();
  const [removeReviewMutation] = useRemoveReviewMutation();

  const handleDeleteReview = () => {
    removeReviewMutation({
      variables: {
        /* eslint-disable-next-line no-underscore-dangle */
        id: reviewData?._id,
      },
      onCompleted: () => {
        showAlert({
          message: 'Отзыв успешно удалён.',
          variant: 'info',
        });
      },
      onError: () => {
        showAlert({
          message: 'Произошла ошибка при удалении отзыва!',
          variant: 'error',
        });
      },
      refetchQueries: [GET_REVIEWS],
    });
    onClose();
  };

  return (
    <>
      <MenuItem sx={styles.menuDeleteText} onClick={onOpen}>
        Удалить
      </MenuItem>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Удаление</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Text>Вы действительно хотите удалить отзыв?</Text>
              <Box>
                <Box sx={styles.modalTextWrapper}>
                  <Text fontSize='xl' mr='0.5vw' minW='3.5vw'>
                    Автор:
                  </Text>
                  <Text>
                    {reviewData.userFirstName}&nbsp;{reviewData.userLastName}
                  </Text>
                </Box>
                <Box sx={styles.modalTextWrapper}>
                  <Text fontSize='xl' mr='0.5vw' minW='3.5vw'>
                    ID:
                  </Text>
                  {/* eslint-disable-next-line no-underscore-dangle */}
                  <Text>{reviewData?._id}</Text>
                </Box>
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant='solid' colorScheme='red' mr={3} onClick={handleDeleteReview}>
              Удалить
            </Button>
            <Button colorScheme='blue' onClick={onClose}>
              Отмена
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeleteReviewModal;
