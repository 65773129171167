import { Flex, HStack, Icon, IconButton, Input, Text } from '@chakra-ui/react';
import React from 'react';
import { FiFilePlus, FiTrash2 } from 'react-icons/fi';

import TextEditor from '../../../../components/text-editor';
import { styles } from './styles';

const DaysContent = ({
  daysContentList,
  setDaysContentList,
  daysNamesList,
  setDaysNamesList,
  setImageDownloadInProcess,
}) => {
  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);

  const handleDeleteDay = (idx) => {
    const result = daysContentList;
    const dayNames = daysNamesList;
    dayNames.splice(idx, 1);
    result.splice(idx, 1);
    setDaysContentList(result);
    setDaysNamesList(dayNames);
    forceUpdate();
  };

  const handleAddDay = () => {
    const result = daysContentList;
    const dayNames = daysNamesList;
    dayNames.push(`День ${dayNames.length + 1}:`);
    result.push('');
    setDaysContentList(result);
    setDaysNamesList(dayNames);
    forceUpdate();
  };

  const handleEditDayName = (event) => {
    const result = daysNamesList;
    result[event.target.id] = event.target.value;
    setDaysNamesList(result);
  };

  return (
    <Flex sx={styles.daysContentWrapper}>
      <Text sx={styles.daysContentTitleText}>Дни путешествия:</Text>
      {daysContentList.map((day, idx) => (
        <Flex flexDirection='column' key={idx}>
          <HStack mt='0.5vw' mb='0.5vw'>
            <Input
              sx={styles.dayNameInput}
              defaultValue={daysNamesList[idx] ? daysNamesList[idx] : `День ${idx + 1}:`}
              onChange={(event) => handleEditDayName(event)}
              placeholder='Название дня'
              required
              maxLength={30}
              id={idx}
              w='15vw'
            />
            <IconButton
              onClick={() => handleDeleteDay(idx)}
              sx={styles.deleteDayBtn}
              icon={<FiTrash2 size='50%' />}
            />
          </HStack>
          <TextEditor
            idx={idx + 1}
            daysContentList={daysContentList}
            setDaysContentList={setDaysContentList}
            setImageDownloadInProcess={setImageDownloadInProcess}
          />
        </Flex>
      ))}
      <Flex sx={styles.dayContentBtnWrapper} onClick={handleAddDay}>
        <Flex sx={styles.dayContentBtn}>
          <Icon as={FiFilePlus} sx={styles.dayContentBtnIcon} />
          <Text sx={styles.dayContentBtnText}>Нажмите, чтобы добавить день путешествия</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default DaysContent;
